/*=========================================================================================
  File Name: moduleLanguageActions.js
  Description: Language Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddLanguage({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Language/AddLanguage", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllLanguages({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Language/GetAllLanguages")
        .then((response) => {
          commit('SET_Language', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateLanguage({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Language/UpdateLanguage", item)
        .then((response) => {
          commit('UPDATE_Language', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetLanguage({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/Language/GetLanguage", itemid)
        .then((response) => {
          commit('UPDATE_Language', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteLanguage({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Language/DeleteLanguage?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
