var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vx-row ml-5"},[_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/4",attrs:{"color":_vm.mainTabName =='profile' ? 'primary' : '#0084FF',"to":{
                name: 'doctor-add/Edit',
                params: { ID: _vm.$route.params.ID },
              }}},[_vm._v(_vm._s(_vm.$t("Profile")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/4",attrs:{"color":_vm.mainTabName=='ReservationSession' ? 'primary' : '#0084FF',"to":{
                name: 'ReservationSession',
                params: { ID: _vm.$route.params.ID },
              }}},[_vm._v(_vm._s(_vm.$t("Appointment")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/4",attrs:{"color":_vm.mainTabName=='AvailabilitySchedule' ? 'primary' : '#0084FF',"to":{
                name: 'AvailabilitySchedule',
                params: { ID: _vm.$route.params.ID },
              }}},[_vm._v(_vm._s(_vm.$t("Availability Schedule")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }