/*=========================================================================================
  File Name: moduleCurrencyActions.js
  Description: Currency Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddCurrency({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Currency/AddCurrency", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllCurrencies({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Currency/GetAllCurrencies")
        .then((response) => {
          commit('SET_Currency', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateCurrency({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Currency/UpdateCurrency", item)
        .then((response) => {
          commit('UPDATE_Currency', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetCurrency({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/Currency/GetCurrency", itemid)
        .then((response) => {
          commit('UPDATE_Currency', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteCurrency({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Currency/DeleteCurrency?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
