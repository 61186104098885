<template>
  <div id="data-list-list-view">
    <vs-prompt
      v-bind:title="$t('New')"
      @cancel="clearExperienceModel"
      @accept="saveExperienceModel"
      @close="clearExperienceModel"
      :is-valid="validateExperienceModel"
      :active.sync="activePrompt"
      :cancel-text="$t('Cancel')"
      :accept-text="$t('Save')"
    >
      <VuePerfectScrollbar
        class="scroll-area p-4"
        :settings="settings"
        :key="$vs.rtl"
      >
        <form @submit.prevent style="max-height:75vh">
          <label for="from" class="vs-input--label"> {{ $t("TitleEN") }}</label>
          <vs-input
            class="w-full mt-4"
            v-model="experienceModel.TitleEN"
            name="TitleEN"
          />

          <label for="from" class="vs-input--label"> {{ $t("TitleAR") }}</label>
          <vs-input
            class="w-full mt-4"
            v-model="experienceModel.TitleAR"
            name="TitleAR"
          />

          <label for="from" class="vs-input--label">
            {{ $t("ReferenceAreaEN") }}</label
          >
          <vs-input
            class="w-full mt-4"
            v-model="experienceModel.ReferenceAreaEN"
            name="ReferenceAreaEN"
          />

          <label for="from" class="vs-input--label">
            {{ $t("ReferenceAreaAR") }}</label
          >
          <vs-input
            class="w-full mt-4"
            v-model="experienceModel.ReferenceAreaAR"
            name="ReferenceAreaAR"
          />

          <div>
            <div class="mt-5">
              <label for="from" class="vs-input--label">
                {{ $t("FromDate") }}</label
              >
              <datepicker
                placeholder="from"
                v-model="experienceModel.DateFrom"
                class="vx-col  w-full"
                name="from"
                v-validate="'required'"
              ></datepicker>
            </div>
            <div class="mt-5">
              <label for="to" class="vs-input--label"> To</label>
              <datepicker
                placeholder="to"
                v-model="experienceModel.DateTo"
                class="vx-col  w-full"
                name="to"
                v-validate="'required'"
              ></datepicker>
            </div>
          </div>
             <vs-card title="icon" class="mt-5">
          <h4>{{ $t("Image") }}</h4>
          <template v-if="experienceModel.AttachPath">
            <div
              class="img-container w-64 mx-auto flex items-center justify-center"
            >
              <img :src="baseURL + experienceModel.AttachPath" alt="img" class="responsive" />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button
              class="mr-4"
              type="flat"
              @click="$refs.updateImgInput.click()"
              >{{ $t("UpdateImage") }}</vs-button
            >
            <vs-button type="flat" color="#999" @click="experienceModel.AttachPath = null">{{
              $t("RemoveImage")
            }}</vs-button>
          </div>
            </vs-card>
        </form>
      </VuePerfectScrollbar>
    </vs-prompt>

    <div>
      <vx-card class="flex-1" v-bind:title="$t('Experiences')">
        <vs-button
          type="border"
          class="mb-4 mt-4"
          icon-pack="feather"
          icon="icon-plus"
          @click="activePrompt = true"
        >
          {{ $t("AddNew") }}
        </vs-button>
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="doctorModel.DoctorExperiences"
        >
          <template slot="thead">
            <vs-th> {{ $t("TitleEN") }}</vs-th>
            <vs-th> {{ $t("TitleAR") }}</vs-th>
            <vs-th> {{ $t("ReferenceAreaEN") }}</vs-th>
            <vs-th> {{ $t("ReferenceAreaAR") }}</vs-th>

            <vs-th> {{ $t("DateFrom") }}</vs-th>
            <vs-th> {{ $t("DateTo") }}</vs-th>
            <vs-th> {{ $t("Attachment") }}</vs-th>
            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.TitleEN }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.TitleAR }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.ReferenceAreaEN }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.ReferenceAreaAR }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DateFrom }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DateTo }}
                  </p>
                </vs-td>
                 <vs-td>
                      <!-- <a :href="tr.URL" download>Download</a> -->
                      <a :href="baseURL + tr.AttachPath" download target="_blank"
                        ><feather-icon
                          icon="DownloadIcon"
                          svgClasses="w-5 h-5 hover:text-danger stroke-current"
                          class="ml-2"
                      /></a> 
                    </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <vs-button
                    class="text-lg mx-2"
                    color="danger"
                    @click.stop="openConfirm(tr)"
                    >{{ $t("Delete") }}</vs-button
                  >
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
// import moduleCountry from "@/store/settings/country/moduleCountry.js";
// import moduleGender from "@/store/settings/gender/moduleGender.js";
// import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
// import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";

export default {
  components: {
    VuePerfectScrollbar,
    Datepicker

    // vSelect,
  },
  props: {
    doctorModel: {
      type: Object,
      required: true
    }
  },
  computed: {
    validateExperienceModel() {
      return (
        this.experienceModel.TitleEN != undefined &&
        this.experienceModel.TitleAR != undefined &&
        this.experienceModel.ReferenceAreaEN != undefined &&
        this.experienceModel.ReferenceAreaAR != undefined &&
        this.experienceModel.DateFrom != undefined &&
        this.experienceModel.DateFrom != undefined
      );
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    genders() {
      return this.$store.state.GenderList.genders;
    },
    specialities() {
      return this.$store.state.SpecialtyList.specialities;
    },
    languages() {
      return this.$store.state.LanguageList.languages;
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      experienceModel: {},
      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile"
    };
  },
  methods: {
     updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg",input).then(response=>{
           this.experienceModel.AttachPath = response.data;
         
      });
      
    },
    refresh() {
      var old = this.doctorModel.BioEN;
      this.doctorModel.BioEN = "";
      this.doctorModel.BioEN = old;
    },
    clearExperienceModel() {
      this.activePrompt = false;
      this.experienceModel = {};
    },
    saveExperienceModel() {
      // this.experienceModel.DateFrom = moment(
      //   String(this.experienceModel.DateFrom)
      // ).format("MM/DD/YYYY hh:mm");
      // this.experienceModel.DateTo = moment(
      //   String(this.experienceModel.DateTo)
      // ).format("MM/DD/YYYY hh:mm");

      this.doctorModel.DoctorExperiences.push(this.experienceModel);
      this.clearExperienceModel();
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },

    acceptAlert() {
      this.deleteExperience(this.rowDataForDelete);

      window.showDeleteSuccess();
    },
    deleteExperience(data) {
      if (data.ID > 0) {
        // this.$store.dispatch("SubjectExamRoleRuleList/removeSubjectExamRoleRole",  data);
        this.$store.dispatch("DoctorList/removeExperience", data);
      }

      const ItemIndex = this.doctorModel.DoctorExperiences.findIndex(
        p => p.TitleEN == data.TitleEN
      );
      this.doctorModel.DoctorExperiences.splice(ItemIndex, 1);
    }
  },
  created() {
    // if (!moduleCountry.isRegistered) {
    //   this.$store.registerModule("CountryList", moduleCountry);
    //   moduleCountry.isRegistered = true;
    // }
    // if (!moduleGender.isRegistered) {
    //   this.$store.registerModule("GenderList", moduleGender);
    //   moduleGender.isRegistered = true;
    // }
    // if (!moduleSpecialty.isRegistered) {
    //   this.$store.registerModule("SpecialtyList", moduleSpecialty);
    //   moduleSpecialty.isRegistered = true;
    // }
    // if (!moduleLanguage.isRegistered) {
    //   this.$store.registerModule("LanguageList", moduleLanguage);
    //   moduleLanguage.isRegistered = true;
    // }

    // this.$store.dispatch("LanguageList/GetAllLanguages");
    // this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    // this.$store.dispatch("GenderList/GetAllGenders");
    // this.$store.dispatch("CountryList/GetAllCountries");
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
