/*=========================================================================================
  File Name: moduleCurrencyMutations.js
  Description: Currency Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.currencies.unshift(item)
  },
  SET_Currency(state, currencies) {
    state.currencies = currencies
  },
  UPDATE_Currency(state, Currency) {
    const CurrencyIndex = state.currencies.findIndex((p) => p.ID == Currency.ID)
    Object.assign(state.currencies[CurrencyIndex], Currency)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.currencies.findIndex((p) => p.ID == itemId)
    state.currencies.splice(ItemIndex, 1)
},
}
