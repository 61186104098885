<template>
  <div class="vx-row ml-5">
       <vs-button
              class="mb-5 ml-5 lg:w-1/4"            
              :color="mainTabName =='profile' ? 'primary' : '#0084FF'"
               :to="{
                  name: 'doctor-add/Edit',
                  params: { ID: $route.params.ID },
                }"
              >{{ $t("Profile") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/4"
               :color="mainTabName=='ReservationSession' ? 'primary' : '#0084FF'"
               :to="{
                  name: 'ReservationSession',
                  params: { ID: $route.params.ID },
                }"
              >{{ $t("Appointment") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/4"
              :color="mainTabName=='AvailabilitySchedule' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'AvailabilitySchedule',
                  params: { ID: $route.params.ID },
                }"
              >{{ $t("Availability Schedule") }}</vs-button
            >

    </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
   props: {
    mainTabName: {
      type: String,
      required: true
    }
  },
  methods: {
   
  }
};
</script>
<style>

</style>
