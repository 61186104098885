<template>
  <div>
    <vs-alert color="danger" title="Course Not Found" :active.sync="doctor_not_found">
      <span>doctor record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'Doctor' }" class="text-inherit underline"
          >All doctor</router-link
        >
      </span>
    </vs-alert>
    <div v-if="!doctor_not_found || (this.$route.params.ID && this.doctorModel.ID)">
      <feather-icon
        icon="ArrowLeftIcon"
        svgClasses="h-8"
        style="cursor: pointer"
        @click="$router.push('/Doctor')"
      />
      <doctor-Tab class="mt-4" :mainTabName="'profile'" />

      <!-- <div class="vx-row ml-5">
       <vs-button
              class="mb-5 ml-5 lg:w-1/4"


              >{{ $t("Profile") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/4"
              color="#0084FF"
               :to="{
                  name: 'ReservationSession',
                  params: { ID: $route.params.ID },
                }"
              >{{ $t("Appointment") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/4"
              color="#0084FF"
              :to="{
                  name: 'AvailabilitySchedule',
                  params: { ID: $route.params.ID },
                }"
              >{{ $t("Availability Schedule") }}</vs-button
            >

    </div> -->

      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <doctor-tab-main class="mt-4" :doctorModel="doctorModel" />
              </div>
            </vs-tab>
            <vs-tab :label="$t('Education')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <doctor-tab-education class="mt-4" :doctorModel="doctorModel" />
              </div>
            </vs-tab>
            <vs-tab :label="$t('Experiences')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <doctor-tab-experience class="mt-4" :doctorModel="doctorModel" />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button
              class="mr-6"
              @click="ConfirmUser"
              v-if="CanWrite && !doctorModel.IsConfirmed && isFormValid"
              >{{ $t("Confirm") }}</vs-button
            >
            <vs-button
              class="mr-6"
              @click="submitData"
              :disabled="!isFormValid"
              v-if="CanWrite"
              >{{ $t("Save") }}</vs-button
            >
            <vs-button type="border" color="danger" :to="{ name: 'Doctor' }">{{
              $t("Cancel")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DoctorTabMain from "./DoctorTabMain.vue";
import DoctorTabEducation from "./DoctorTabEducation.vue";

import DoctorTabExperience from "./DoctorTabExperience.vue";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import doctorTab from "@/views/tabs/doctorTab.vue";

export default {
  components: {
    DoctorTabMain,
    DoctorTabEducation,
    DoctorTabExperience,
    doctorTab,
  },
  data() {
    return {
      CanWrite: false,
      DoctorTabSessionPriceoctorModel: {
        languages: [],
      },
      doctor_not_found: false,
      activeTab: 0,
      doctorModel: {
        languages: [],
        mainFocus: [],
        specialities: [],
        DoctorSpecialities: [],
        DoctorRates: [],
        DoctorLanguages: [],
        DoctorMainFocus: [],
        DoctorEducations: [],
        DoctorExperiences: [],
        DoctorSessionPrices: [],
        DoctorSessions: [],
        DoctorPackages: [],
        DoctorLinks: [],
        RecommendedLevel: 0,
      },
    };
  },
  computed: {
    Currencies() {
      return this.$store.state.CurrencyList.Currencies;
    },

    isFormValid() {
      return !this.errors.any() && this.doctorModel.NameEN
      && this.doctorModel.GenderID && this.doctorModel.CountryID && this.doctorModel.CityID

    },
  },
  methods: {
    submitData() {
      this.doctorModel.DoctorLanguages = this.$store.state.DoctorList.languages.map(
        function (val) {
          var obj = {};
          obj.LanguageID = val;
          return obj;
        }
      );
      this.doctorModel.DoctorSpecialities = this.$store.state.DoctorList.specialities.map(
        function (val) {
          var obj = {};
          obj.SpecialtyID = val;
          return obj;
        }
      );
      debugger;
      if (this.doctorModel.ID !== null && this.doctorModel.ID >= 0) {
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/UpdateDoctor", this.doctorModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            this.$router.push({ name: "Doctor" });
            window.showSuccess(res.data.message);
            this.initDoctorModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            window.showError(err.response.data.message);
            console.error(err);
          });
      } else {
        delete this.doctorModel.ID;
        this.doctorModel.popularity = 0;
        this.doctorModel.UserType = "Doctor";
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/AddDoctor", this.doctorModel)
          .then((res) => {
            this.$store.commit("SET_SetTitle", "Dr. " + this.doctorModel.Name);
            console.error(res);
            this.$vs.loading.close();
            this.$router.push({ name: "Doctor" });
            window.showSuccess(res.data.message);
            //  this.$store.state.courseList.search.pageNum=1;
            this.initDoctorModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showError(err.response.data.message);
          });
      }
    },
    ConfirmUser() {
      debugger;
      this.doctorModel;
      debugger;
      this.$store
        .dispatch("DoctorList/ConfirmUser", this.doctorModel.UserID)
        .then((res) => {
          console.error(res);
          this.$vs.loading.close();
          window.showSuccess(res.data.message);

          this.initDoctorModelValues();
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.error(err);
          window.showError(err.response.data.message);
        });

      this.$router.push({ name: "Doctor" });
    },
    initValues() {
      // this.doctorModel = {};
      this.doctorModel.specialities = [];
      this.doctorModel.languages = [];
    },
    initDoctorModelValues() {
      this.doctorModel.DoctorSpecialities = [];
      this.doctorModel.DoctorRates = [];
      this.doctorModel.DoctorLanguages = [];
      this.doctorModel.DoctorMainFocus = [];
      this.doctorModel.DoctorEducations = [];
      this.doctorModel.DoctorExperiences = [];
      this.doctorModel.DoctorSessionPrices = [];
      this.doctorModel.DoctorSessions = [];
      this.doctorModel.DoctorPackages = [];
      this.doctorModel.DoctorLinks = [];
      this.$store.commit("DoctorList/SET_languages", []);
      this.$store.commit("DoctorList/SET_specialities", []);
    },
  },

  created() {
    this.CanWrite =
      JSON.parse(localStorage.getItem("userInfo")).CanWrite ||
      JSON.parse(localStorage.getItem("userInfo")).userRole == "admin"||
      JSON.parse(localStorage.getItem("userInfo")).userRole == "accountmanager";

    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    this.initDoctorModelValues();
    this.initValues();
    //  this.doctorModel.test = "";
    if(!this.Currencies || this.Currencies.length==0 )
       this.$store.dispatch("CurrencyList/GetAllCurrencies");

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    // var vm = this;
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("DoctorList/GetDoctor", ID).then((res) => {
        this.doctorModel = res.data.Data;
        // if (res.data.Data instanceof Array) this.doctorModel = res.data[0].Data;
        // else this.doctorModel = res.data.Data;
        // vm.doctorModel.update = true;

        //this.$set(this.$route.meta,'title','Dr. '+ this.doctorModel.Name);
        this.$store.commit("SET_SetTitle", "Dr. " + this.doctorModel.Name);
        this.doctorModel.languages = this.doctorModel.DoctorLanguages.map(
          (a) => a.LanguageID
        );

        console.log(this.doctorModel.DoctorSpecialities);

        this.doctorModel.specialities = this.doctorModel.DoctorSpecialities.map(
          (a) => a.SpecialtyID
        );
          console.log(this.doctorModel.specialities);

        this.$store.commit("DoctorList/SET_languages", this.doctorModel.languages);
        this.$store.commit("DoctorList/SET_specialities", this.doctorModel.specialities);
      });
    }
  },
};
</script>
