/*=========================================================================================
  File Name: moduleGenderMutations.js
  Description: Gender Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.genders.unshift(item)
  },
  SET_Gender(state, genders) {
    state.genders = genders
  },
  UPDATE_Gender(state, Gender) {
    const GenderIndex = state.genders.findIndex((p) => p.ID == Gender.ID)
    Object.assign(state.genders[GenderIndex], Gender)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.genders.findIndex((p) => p.ID == itemId)
    state.genders.splice(ItemIndex, 1)
},
}
