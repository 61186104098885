/*=========================================================================================
  File Name: moduleLanguageMutations.js
  Description: Language Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.languages.unshift(item)
  },
  SET_Language(state, languages) {
    state.languages = languages
  },
  UPDATE_Language(state, Language) {
    const LanguageIndex = state.languages.findIndex((p) => p.ID == Language.ID)
    Object.assign(state.languages[LanguageIndex], Language)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.languages.findIndex((p) => p.ID == itemId)
    state.languages.splice(ItemIndex, 1)
},
}
